<template>
  <div class="creator">
    <picture :class="{ select: isSelected }">
      <source :srcset="jpg" />
      <img
        :src="jpg"
        :alt="name"
        :class="{ selected: isSelected, bgWhite: isInstagram }"
      />
    </picture>
    <p>{{ name }}</p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Nominee",
  props: {
    name: String,
    jpg: String,
    webp: String,
    category: String,
    customId: Number,
  },
  methods: {},
  computed: {
    isSelected() {
      return this.$store.getters.isNomineeSelected(
        this.category,
        this.customId
      );
    },
    isInstagram() {
      return this.name == "Instagram" ? true : false;
    },
  },
});
</script>

<style lang="scss" scoped>
.bgWhite {
  background: white;
}

.creator {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  // margin-bottom: 1.3rem;

  picture {
    height: 75px;
    width: 75px;
    position: relative;
  }

  picture.select::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 80px;
    width: 80px;
    background: rgba(#fffc37, 0.5);
    border-radius: 50%;
    display: inline-block;
  }

  img {
    width: inherit;
    height: inherit;
    border-radius: 50%;
  }

  p {
    margin-top: 0.7rem;
  }

  .selected {
    border: 2px solid #fffc37;
    background-color: #fffc37;
    background-blend-mode: multiply;
  }
}
@media (min-width: 768px) {
  .creator {
    margin-bottom: 0px;
    margin-top: 1rem;
  }
}

@media (min-width: 992px) {
  .creator {
    margin-top: 0px;
    img {
      width: 90px;
      height: 90px;
    }

    picture {
      height: 90px;
      width: 90px;

      &.select::before {
        height: 95px;
        width: 95px;
      }
    }
  }
}
</style>
