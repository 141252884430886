<template>
  <nav>
    <router-link to="/vote/storahjarta" v-slot="{ isExactActive }" custom>
      <div :class="[isExactActive && 'router-link-exact-active']"></div>
    </router-link>
    <router-link to="/vote/forebild" v-slot="{ isExactActive }" custom>
      <div :class="[isExactActive && 'router-link-exact-active']"></div>
    </router-link>
    <router-link to="/vote/godagarning" v-slot="{ isExactActive }" custom>
      <div :class="[isExactActive && 'router-link-exact-active']"></div>
    </router-link>
    <router-link to="/vote/preferredplatform" v-slot="{ isExactActive }" custom>
      <div :class="[isExactActive && 'router-link-exact-active']"></div>
    </router-link>
  </nav>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Navigation",
  data() {
    return {};
  },
  props: {},
  methods: {},
});
</script>

<style lang="scss" scoped>
nav {
  width: 100%;
  display: flex;
  justify-content: center;

  margin-bottom: 1.3rem;
}

div {
  width: 10px;
  height: 10px;
  border: 1px solid #fffc37;
  background: none;

  margin: 0 5px 0 0;
  padding: 0;

  text-decoration: none;
  border-radius: 50%;
}

a:last-child > button {
  margin: 0;
}

a:hover,
div:hover {
  cursor: default;
}
</style>
